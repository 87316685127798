.radioEmpty {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #e9e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioFull {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(159deg, #fb8332 0%, #f8c332 112%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskHeader:hover {
  color: rgb(108, 61, 171);
  font-weight: 600;
}
