.App {
  text-align: center;
}

#root {
  font-weight: 400;
}

.uploadbutton {
  background-color: #fff !important;
  color: #6c3dab !important;
  margin: 10px;
  &:hover {
    background-color: #6c3dab !important;
    color: #fff !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

input.MuiSelect-nativeInput {
  border: none !important;
}

.clientEmail {
  background: #fff !important;
  border-radius: 6px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.introMain {
  width: 1200px;
  .introLeft {
    width: 500px;
  }
  .introRight {
    width: 534px;
  }
}

@media only screen and (max-width: 1400px) {
  .introMain {
    width: 1105px;
    .introLeft {
      width: 425px;
    }
    .introRight {
      width: 453px;
    }
  }
}

.choosePageSmall {
  width: 205px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .chooseSubTitle {
    display: none;
  }
  .chooseItemBtn {
    display: none;
  }
  .chooseMessage {
    display: none;
  }

  .chooseItem {
    width: 203px;
    display: flex;
    flex-direction: column;
    border-radius: 10.4px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.07);
    border: solid 0.5px #eaeaea;
    background-color: #fff;
    cursor: pointer;
  }

  .chooseTitle {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .chooseImage {
    width: 202px;
  }

  .chooseBody {
    padding: 0 10px;
  }

  .imageWrapper {
    height: 84px;
  }
}

.choosePage {
  margin: 0px auto;
  margin-top: 20px;
  align-self: center;
  gap: 10px;
  display: flex;
  flex-direction: column;

  .chooseItem {
    width: 400px;
    background: #fff;
    border-radius: 26px;
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #eaeaea;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .chooseBody {
    padding: 0 40px;
  }

  .chooseTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 48px;
  }

  .chooseSubTitle {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
  }

  .chooseMessage {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    margin-top: 20px;
  }

  .chooseImage {
    width: fit-content;
  }
  .imageWrapper {
    height: 190px;
  }
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.comment-add {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.comment-text {
  font-size: 12px;
  font-family: "Nunito";
  vertical-align: middle;
  display: flex;

  span {
    margin-right: 8px;
  }
}

.comment-helper {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

#deviceSelector {
  display: none;
}

#showInvisible {
  display: none;
}

.public-DraftEditor-content {
  margin-bottom: 15px;
}

.comment-new {
  background: #d70404;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -8px;
  border-radius: 7px;
  right: -6px;
  cursor: pointer;
}

.has-comments {
  background: #d70404;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -8px;
  border-radius: 7px;
  right: -8px;
  cursor: pointer;
}

.has-comments-text {
  background: #d70404;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  border-radius: 7px;
  right: -12px;
  cursor: pointer;
}

.paragrpah-wrapper {
  display: flex;
  flex-direction: column-reverse;

  & .paragrpah-editor-toolbar {
    display: flex;
    justify-content: flex-start;
    gap: 0px;
    cursor: pointer;
    margin-top: 0px;
    border: none;
  }

  .boldtext {
    margin-bottom: 0px;

    .iconButton {
      margin: 0 4px;
      outline: none;
      width: 20px !important;
      min-width: 20px;
      padding: 2px;
      border-radius: 0px;
      background: #f9f9f9;
    }
  }
  .boldtexthidden {
    height: 26px;
    .iconButton {
      display: none;
    }
  }
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.notificationWrapper::-webkit-scrollbar {
  width: 3px;
  background-color: #292b28;
}

.notificationWrapper::-webkit-scrollbar-thumb {
  background-color: #4a4646;
}

#svd-settings {
  display: none;
}

.commentManager {
  ::-webkit-scrollbar {
    width: 1px;
    background-color: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
  }
}

.svc-designer-header {
  display: none;
}

.svc-toolbox {
  display: none;
}

.svc-tabbed-menu {
  padding-left: 30px;
}

div#svd-grid-expand {
  display: none;
}

.svc-item__banner {
  background-image: linear-gradient(109deg, #3023ae -37%, #c86dd7 104%);

  .svc-toolbox__item-title {
    color: #fff !important;
  }
  .svc-toolbox__item-icon {
    filter: brightness(0) invert(1) !important;
  }
}

.svc-page-navigator-item__banner {
  &.svc-item__banner {
    background-image: none;
  }
}

.inputWithVal {
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8 !important;
  }
  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #7c48c2 !important;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #7c48c2 !important;
  }
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  box-shadow: none !important;
}

.inputWithValDisabled {
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8 !important;
  }
  box-shadow: none !important;
}

.svc-creator .svc-page__add-new-question {
  display: none;
}

.svc-toolbox__item-icon {
  filter: brightness(0) invert(1) !important;
}

.svc-side-bar__container {
  width: 350px !important;
  min-width: 350px !important;
}

.showSearch {
  width: 250px;
  transition: all 2s linear;
  display: block;
  opacity: 1;
}

.hideSearch {
  display: none;
  opacity: 0;
}

.pageWrapper {
  border-radius: 19px;
  // padding-bottom: 15px;
  background-color: #f5f8f7;
  font-family: Nunito;
}

.sv-tagbox__item {
  height: auto !important;
  background: #7c48c2;
}
.sd-tagbox-item_clean-button {
  background: #7c48c2;
  padding-inline-start: 0px;
}

.sd-action {
  color: #7c48c2;
}

.sd-text::-webkit-input-placeholder {
  color: #b8b8b8 !important;
}
.sd-comment::-webkit-input-placeholder {
  color: #b8b8b8 !important;
}

// .sd-input {
//  &.sd-text::-webkit-input-placeholder {
//   color: #b8b8b8;
//   }
//   &.sd-comment::-webkit-input-placeholder {
//     color: #b8b8b8;
//     }
// }

.svc-panel__add-new-question .svc-text {
  color: #7c48c2;
}

.svc-page__add-new-question .svc-text {
  color: #7c48c2;
}

.lynxiDropDowm > * {
  font-family: Nunito !important;
}

// .select2-results__option[aria-selected=true] {
//   display: none;
// }

@media only screen and (max-width: 900px) {
  .pageWrapper {
    margin: 0px 2% !important;
  }
  .helpCenter {
    display: none !important;
  }

  .briefHead {
    margin-left: 0px !important;
    font-size: 16px !important;
  }

  .sd-title {
    width: 65%;
  }

  .briefnav {
    width: 90% !important;
  }

  .briefmsg {
    padding: 0 30px !important;
  }
  .briefmsgInner {
    margin-top: 20px !important;
  }
}

body {
  /* SurveyJS Creator V2 */
  --primary: #7c48c2;
  // --primary-light: rgba(26, 183, 250, 0.1);
  // --foreground: #ededed;
  // --primary-foreground: #ffffff;
  --secondary: #7c48c2;
  // --background: #555555;
  // --background-dim: #4d4d4d;
  // --background-dim-light: #4d4d4d;
}

.briefline {
  &:hover {
    .actionBtn {
      background-image: linear-gradient(100deg, #3023ae -34%, #c86dd7 104%);
      color: #fff;
    }
  }
}

.mobileTopImage {
  max-width: 60vw;
  // margin: 0 0 26px 28px;
  object-fit: contain;
}

.mobileBottomImage {
  width: 100vw;
  max-width: 640px;
  object-fit: contain;
}

.addNewQuestion {
  &:hover {
    background-image: linear-gradient(100deg, #3023ae -34%, #c86dd7 104%);
    color: #fff;
    & .sv-svg-icon {
      display: none;
    }
    & .sv-svg-icon.svc-toolbox__item-icon {
      display: block;
    }
  }
  & .sv-svg-icon {
    display: block;
  }
  & .svc-toolbox__item-icon {
    display: none;
  }
}

.datePicker {
  z-index: 4000 !important;
}
