.basicEditor {
  border: 1px solid #ccc;
  padding: 0px 12px;
  background: #f9f9f9;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  color: #000;
  font-size: 14px;
  font-family: "Nunito";
  min-height: 100px;
  max-height: 100px;
  line-height: 1.3;
  overflow-y: auto;
  &:focus {
    border-width: 3px !important;
  }
  &:hover {
    border: 1px solid #7c48c2;
  }
  & .public-DraftStyleDefault-block {
    margin: 0.4em 0 !important;
  }
}
.transcript-editor {
  max-height: 300px !important;
}

.basicEditorWrapper {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  & .rdw-editor-toolbar {
    background: none !important;
    border: none !important;
  }

  & .basicEditorFocus {
    border: 2px solid #7c48c2 !important;
  }

  & .post-editor-toolbar {
    display: flex;
    justify-content: flex-end;
    gap: 0px;
    cursor: pointer;
    margin-top: 8px;

    & .rdw-link-modal {
      bottom: -70px;
      right: 50px !important;
      top: auto;
      left: auto;
    }
  }

  & .post-editor-toolbar-top {
    display: flex;
    justify-content: flex-end;
    gap: 0px;
    cursor: pointer;
    margin-top: 8px;
    position: absolute;
    top: -50px;
    right: 0;

    & .rdw-link-modal {
      left: auto;
      right: 0px;
    }
  }

  & .linkButtons {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  & .rdw-option-wrapper {
    background: none;
  }

  & .rdw-emoji-modal {
    left: auto;
    right: 0px;
  }
}

.paragrpah-wrapper {
  display: flex;
  flex-direction: column-reverse;

  & .paragrpah-editor-toolbar {
    display: flex;
    justify-content: flex-start;
    gap: 0px;
    cursor: pointer;
    margin-top: 0px;
    border: none;
  }

  .boldtext {
    margin-bottom: 0px;

    .iconButton {
      margin: 0 4px;
      outline: none;
      width: 20px !important;
      min-width: 20px;
      padding: 2px;
      border-radius: 0px;
      background: #f9f9f9;
    }
  }
  .boldtexthidden {
    height: 26px;
    .iconButton {
      display: none;
    }
  }
}
